import React, { FC, useEffect, useState, useContext } from 'react';
import { graphql } from 'gatsby';
import {
  project,
  content_title,
  content_image,
  content_video,
  content_paragraph,
  content_quote,
  content_gallery,
  content_stackedImages,
  content_credits,
  content_iframe,
  layout,
  contentfulTitle,
  contentfulIframe,
  contentfulVideo,
  contentfulParagraph,
  contentfulQuote,
  contentfulGallery,
  contentfulImages,
  contentfulCredits,
  contentfulImage
} from '../util/models';
import {
  Title,
  Image,
  Video,
  Paragraph,
  Quote,
  Gallery,
  Images,
  Credits,
  Iframe
} from 'cobox-component-library';
import styled from 'styled-components';
import { ProjectComponent, breakpoint } from '../util/constant';
import Header_Desktop from '../components/section/desktop/desktop_header';
import Header_Mobile from '../components/section/mobile/mobile_header';
import { ShareIcons } from '../components/shareIcons';
import { deviceSize } from '../util/styledComponents';
import { SEO } from '../components/seo';
import { SiteContext } from '../context';
import ReactPlayer from 'react-player';

const Flex = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 70px 30px 70px;
  box-sizing: border-box;
  ${deviceSize.lessThan(breakpoint.mobile)`
    padding: 10px;
    padding-top: 70px;
    padding-bottom: 100px;
  `}
`;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-gap: 30px;
`;

const Grid = styled.div<{ isSingle: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  ${deviceSize.lessThan(breakpoint.mobile)`
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  `}
  grid-gap: 30px;
  box-sizing: border-box;
  width: 100%;
`;

const Column = styled.div<{
  hasLine?: boolean;
  minHeight?: string;
}>`
  border-right: ${p => (p.hasLine ? '1px' : 0)} solid
    ${p => p.theme.colour.grey2};
  padding-right: ${p => (p.hasLine ? '30px' : 0)};
  box-sizing: border-box;
  min-height: ${p => (p.minHeight ? p.minHeight : 'auto')};
  height: 100%;
  box-sizing: border-box;
`;

type Props = {
  data: any;
};

const ProjectPage: FC<Props> = ({ data }) => {
  const {
    headerLogo,
    socialLinks,
    showreelUrl,
    sections,
    clientLoginLink,
    links
  } = data.contentfulHomePage;

  const {
    layout,
    campaign,
    category,
    seoTitle,
    seoDescription,
    title,
    slug,
    tags
  } = data.contentfulProject;

  const [isSingle, setIsSingle] = useState(false);

  const { browserWidth } = useContext(SiteContext);

  const renderComponent = (
    single: boolean,
    hasLine: boolean,
    name: string,
    object: {}
  ) => {
    const nameArray = name.split('_');
    const typeName = nameArray[nameArray.length - 1] as ProjectComponent;
    switch (typeName) {
      case ProjectComponent.Title:
        const project = object as contentfulTitle;
        return (
          <Column hasLine={hasLine}>
            <Title
              Heading={project.heading}
              SubHeading={project.subHeading ? project.subHeading : ''}
              Text={project.text?.text ? project.text.text : ''}
              Center={false}
            />
          </Column>
        );
      case ProjectComponent.Image:
        const image = object as contentfulImage;
        return (
          <Column hasLine={hasLine} minHeight={'300px'}>
            <Image Image={image.image.file.url} Cropped={image.cropped} />
          </Column>
        );
      case ProjectComponent.Video:
        const video = object as contentfulVideo;
        return (
          <Column hasLine={hasLine}>
            <div style={{ position: 'relative', paddingTop: '56.25%' }}>
              <ReactPlayer
                url={video.link}
                controls={true}
                width='100%'
                height='100%'
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
              />
            </div>
          </Column>
        );
      case ProjectComponent.Paragraph:
        const paragraph = object as contentfulParagraph;
        return (
          <Column hasLine={hasLine}>
            <Paragraph
              Heading={paragraph.heading ? paragraph.heading : ''}
              Text={paragraph.text.text}
              Center={false}
              Buttons={paragraph.buttons ? paragraph.buttons : []}
              onClick={link => {
                window.open(link);
              }}
            />
          </Column>
        );
      case ProjectComponent.Quote:
        const quote = object as contentfulQuote;
        return (
          <Column hasLine={hasLine}>
            {quote.author ? (
              <Quote Quote={quote.quote.quote} Author={quote.author} />
            ) : (
              <Quote Quote={quote.quote.quote} />
            )}
          </Column>
        );
      case ProjectComponent.Gallery:
        const gallery = object as contentfulGallery;
        const images = gallery.images.map(image => image.file.url);
        return (
          <Column hasLine={hasLine}>
            <Gallery Images={images} Square={gallery.square} />
          </Column>
        );
      case ProjectComponent.Images:
        const stackedImages = object as contentfulImages;
        const stackedImageData = stackedImages.images.map(
          image => image.file.url
        );
        return (
          <Column hasLine={hasLine}>
            <Images Images={stackedImageData} />
          </Column>
        );
      case ProjectComponent.Credits:
        const credits = object as contentfulCredits;
        return (
          <Column hasLine={hasLine}>
            <Credits
              Credits={credits.credits}
              Stacked={isSingle ? false : credits.stacked}
              onClick={link => {
                window.open(link);
              }}
            />
          </Column>
        );
      case ProjectComponent.Iframe:
        const iframe = object as contentfulIframe;
        return (
          <Column hasLine={hasLine}>
            <Iframe Link={iframe.link} />
          </Column>
        );
      default:
        return <div>{typeName}</div>;
    }
  };

  useEffect(() => {
    if (browserWidth < 890) {
      setIsSingle(true);
    } else {
      setIsSingle(false);
    }
  }, [browserWidth]);

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        titleStartsWithCobox={true}
      />
      <Header_Mobile
        backButton={true}
        header={{
          client: clientLoginLink,
          showreel: showreelUrl,
          icons: socialLinks,
          menulabels: sections,
          logo: headerLogo
        }}
        contact={links}
      />
      <Header_Desktop
        backButton={true}
        header={{
          client: clientLoginLink,
          showreel: showreelUrl,
          icons: socialLinks,
          menulabels: sections,
          logo: headerLogo
        }}
      />
      <ShareIcons url={`https://cobox.com.au/${slug}`} />
      <Flex>
        <Container>
          {(layout as layout[]).map((layout, index) => (
            <Grid
              isSingle={layout.elements.length === 1}
              key={`Column${index}`}
            >
              {renderComponent(
                isSingle || layout.elements.length === 1,
                !isSingle && layout.elements.length > 1,
                layout.elements[0].__typename,
                layout.elements[0]
              )}
              {layout.elements.length > 1 && (
                <>
                  {renderComponent(
                    isSingle,
                    false,
                    layout.elements[1].__typename,
                    layout.elements[1]
                  )}
                </>
              )}
            </Grid>
          ))}
        </Container>
      </Flex>
    </>
  );
};

export const query = graphql`
  query($id: String) {
    contentfulHomePage {
      # header
      headerLogo {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      socialLinks {
        link
        icon
      }
      showreelUrl
      sections {
        title
        anchor
      }
      clientLoginLink

      # contact
      contactDescription {
        raw
      }
      contactTitle
      links {
        title
        linkUrl
        linkText
      }
    }

    # project
    contentfulProject(id: { eq: $id }) {
      campaign
      category
      seoTitle
      seoDescription {
        seoDescription
      }
      title
      slug
      tags
      layout {
        elements {
          ... on ContentfulComponentCredits {
            credits {
              title
              name
              link
            }
            stacked
          }
          ... on ContentfulComponentImage {
            image {
              file {
                url
              }
            }
            cropped
          }
          ... on ContentfulComponentParagraph {
            text {
              text
            }
            heading
            buttons {
              link
              title
            }
            center
          }
          ... on ContentfulComponentGallery {
            images {
              file {
                url
              }
            }
            square
          }
          ... on ContentfulComponentIframe {
            link
          }
          ... on ContentfulComponentImages {
            images {
              file {
                url
              }
            }
          }
          ... on ContentfulComponentQuote {
            quote {
              quote
            }
            author
            center
          }
          ... on ContentfulComponentTitle {
            heading
            subHeading
            text {
              text
            }
            center
          }
          ... on ContentfulComponentVideo {
            link
            fullWidth
          }
        }
      }
    }
  }
`;

export default ProjectPage;
