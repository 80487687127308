import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Share } from '../../util/constant';

const fbColour = '#3b5998';
const liColour = '#0072b1';
const twColour = '#00acee';
const emColour = 'grey';

const Container = styled.div<{
  icon: 'facebook' | 'linkedin' | 'twitter' | 'mail';
}>`
  height: 50px;
  width: 50px;
  position: relative;
  background-color: ${p =>
    p.icon === 'facebook'
      ? fbColour
      : p.icon === 'linkedin'
      ? liColour
      : p.icon === 'twitter'
      ? twColour
      : emColour};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    filter: brightness(1.2) saturate(0.8);
  }
  :active {
    filter: brightness(1) saturate(0.8);
  }
  flex-grow: 1;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 24px;
  color: white;
`;

type Props = {
  icon: Share;
  url: string;
};

const ShareIcon: FC<Props> = ({ icon, url }) => {
  const popUpClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    window.open(url);
  };

  const getIcon = () => {
    switch (icon) {
      case Share.facebook:
        return faFacebookF;
      case Share.linkedin:
        return faLinkedinIn;
      case Share.twitter:
        return faTwitter;
      case Share.mail:
        return faEnvelope;
      default:
        return faFacebookF;
    }
  };

  return (
    <Container icon={icon} onClick={popUpClick}>
      <Icon icon={getIcon()} />
    </Container>
  );
};

export default ShareIcon;
