import React, { FC } from 'react';
import styled from 'styled-components';
import ShareIcon from './button/shareIcon';
import { deviceSize } from '../util/styledComponents';
import { breakpoint } from '../util/constant';
import { Share } from '../util/constant';

const Container = styled.div`
  position: fixed;
  top: 100px;
  left: 0;
  display: flex;
  z-index: 100;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${deviceSize.lessThan(breakpoint.mobile)`
    flex-direction: row;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
  `}
  background-color: white;
`;

type Props = {
  url: string;
};

export const ShareIcons: FC<Props> = ({ url }) => {
  const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  const twitterURL = `https://twitter.com/share?url=${url}`;
  const linkedinURL = `https://www.linkedin.com/shareArticle?url=${url}`;
  const emailURL = `mailto:?subject=Cobox-Project&body=Check out this Cobox project: ${url}`;

  return (
    <Container>
      <ShareIcon icon={Share.facebook} url={facebookURL} />
      <ShareIcon icon={Share.linkedin} url={linkedinURL} />
      <ShareIcon icon={Share.twitter} url={twitterURL} />
      <ShareIcon icon={Share.mail} url={emailURL} />
    </Container>
  );
};
